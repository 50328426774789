<template>
  <div>
    <div class="termsAndConditions" v-html="termsAndConditions"></div>
    <div class="pointsConditions">
      <h3>Points - Rewards</h3>
      <ul>
        <li>You can collect points for every purchase and product like:</li>
        <ul>
          <li>
            100 points - Every £10 spent
          </li><li>
            10 points - Every Product "like"
          </li><li>
            25 points - Every Product review
          </li>
        </ul>
        <li>You can use these points to buy products cheaper - Every 100 points = £1</li>
        <li>Points are not transferable to another person – you cannot share your loyalty card and points with other people.</li>
        <li>Points are not exchangeable for cash – the earned points or rewards cannot be exchanged for their monetary value.</li>
        <li>Redemption limits – You can do 10 'likes' per day, 30 'likes' per month</li>
        <li>Minimum payment required – £1 (for orders redeeming points)</li>
        <li>Expiration date -  Points are valid for a period of 1 year.</li>
        <li>Order cancelling - The order cancelled by the users leads to the loss of collected points</li>
        <li>Fraud protection - Points can be removed from a user's account who abuses the programme based on fraudulent and suspicious behaviour, suspicious quick surge in points number, attempts of points farming,  failed payment attempts, etc.</li>
        <li>The REWARDS Program may be changed, suspended or terminated at any time without notice.</li>
        <li>Changes may include, among other things, modifying the number of purchases required to qualify for the potential tiers, changing the Rewards values and points expiration periods, imposing additional restrictions, or terminating the REWARDS Program.</li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
// import VueMarkdown from 'vue-markdown'

export default {
  name: 'TermsConditions',
  components: {
    // VueMarkdown
  },
  data: function () {
    return {
      termsAndConditions: ''
    }
  },
  created () {
    axios.get('https://www.iubenda.com/api/terms-and-conditions/36163351')
      .then(response => {
        this.termsAndConditions = response.data.content
      })
  }
}
</script>
<style scoped lang="stylus">
.termsAndConditions {
  display: flex;
  background: white;
  align-items: flex-start;
  overflow-y: auto;
  justify-content: center;
  height 100%;
  flex-wrap wrap;
  padding 50px 10px;
  box-sizing border-box;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  font-family: 'Helvetica Regular', sans-serif

  // >>>h1 {
  //   font-family: 'Bebas Neue', sans-serif;
  // }
}
.pointsConditions {
  display: block;
  background: white;
  align-items: flex-start;
  overflow-y: auto;
  justify-content: center;
  height 100%;
  flex-wrap wrap;
  padding 50px 10px;
  box-sizing border-box;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  font-family: 'Helvetica Regular', sans-serif

  ul {
    padding: 0 0 0 20px;

    li {
      padding: 4px 0;
    }
  }

  // >>>h1 {
  //   font-family: 'Libre Baskerville', serif;
  // }
}
</style>
